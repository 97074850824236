<template>
  <footer id="footer">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="copyright">
          &copy; Copyright <strong>Akeshya</strong>. All Rights Reserved
        </div>
        <div>
          <ul class="d-flex list-unstyled">
            <li class="px-3"><a href="./Terms and conditions.pdf">Terms and conditions</a></li>
            <li class="px-3"><a href="./Refund policy.pdf">Refund policy</a></li>
            <li class="px-3"><a href="./Privacy policy.pdf">Privacy policy</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>

</style>
